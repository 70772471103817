import './App.css';
import Navbar from './components/nav';
import Header from './components/video';
import CardGrid from './components/musictracks';
import FooterC from './components/footerc';
import Story from './components/story';

function App() {
  return (
    <div class="App">
      
<Navbar />
<Header />
<div class='md:m-20'>
<CardGrid />
</div>
<Story />
<FooterC/>

    </div>
  );
}

export default App;
