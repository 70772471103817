import React from 'react';
import logo from './android-chrome-192x192.png';

const Navbar = () => {
  return (
    <nav class="bg-black p-4 shadow-md shadow-olivedarker">
      <div class="container mx-auto flex justify-between items-center">
        <img src={logo} alt="Logo" class="h-10 w-auto animate-pulse" />
        <ul class="md:flex">
          <li>
            <a href="#story" class="text-olive hover:text-gray-300 animate-pulse">The story</a>
          </li>
        </ul>
       
      </div>
    </nav>
  );
};

export default Navbar;