import React from 'react';
import dirindine from '../DIRINDINE.mp3'
import gelato from '../GELATO_GUSTO_PAN.mp3'
import polvere from '../POLVERE DI STELLE.mp3'
import treno from '../QUEL_DANNATO_TRENO_A_LIEVITAZIONE.mp3'
import dirinimg from '../bells.jpg'
import gelimg from '../gelato.jpg'
import polimg from '../polvere.jpg'
import trimg from '../treno.jpg'

const Card = ({ title, image, track }) => {
  return (
    <div class="rounded-3xl overflow-hidden shadow-md shadow-zinc-900 bg-olivebgdark h-96">
      <img class="w-full h-48 object-cover invert grayscale shadow-md shadow-zinc-900" src={image} alt={title} />
      <div class="px-6 py-4">
        <div class="font-bold text-lg pb-2 text-center">{title}</div>
        <div class='flex w-full justify-center items-center'>
        <audio controls class='w-4/5 rounded-full shadow-md bg-zinc-900'>
          <source src={track} type="audio/mpeg" />
        </audio>
        </div>
      </div>
    </div>
  );
};

const CardGrid = () => {
  const tracks = [
    {
      id: 1,
      title: 'Dirindine',
      image: dirinimg,
      track: dirindine,
    },
    {
      id: 2,
      title: 'Gelato gusto pan',
      image: gelimg,
      track: gelato,
    },
    {
      id: 3,
      title: 'Polvere di stelle',
      image: polimg,
      track: polvere,
    },
    {
      id: 4,
      title: 'Quel dannato treno a lievitazione',
      image: trimg,
      track: treno,
    },
  ];

  return (
    <div class="container mx-auto py-6">
      <div class="flex flex-wrap">
        {tracks.map((track) => (
          <div class="w-full md:w-1/2 lg:w-1/4 px-4 mb-8" key={track.id}>
            <Card title={track.title} image={track.image} track={track.track} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGrid;