import React from 'react';
import video from '../colvid.mp4';
import brain from '../brainbg.png'

const Header = () => {
  return (
    <><h1 class='story2 text-lg text-olive sm:text-5xl font-extrabold ml-10'>Colostomia</h1>
    <header class='flex justify-center items-center mx-20 mb-20'>
        <div class='rounded-lg flex justify-center'></div>
        <img class="rounded-full mt-5 animate-pulse shadow-lg shadow-zinc-900 brightness-50" src={brain}></img>
      {/* <video class="md:w-3/4 rounded-md shadow-md shadow-zinc-900" autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </video> */}
    </header>
    </>
  );
};

export default Header;