import G from '../g.png'


function Story() {


    return (
        <div class="flex flex-col xl:flex-row">
            {/* Colonna sinistra */}

            <div class="xl:w-1/2 flex justify-center items-center px-4">
                <p id="story" class="text-left p-5 shadow-md text-olivebgdark bg-black shadow-olive opacity-80">
                    Un giovane programmatore, 
                    durante l'ennesima giornata in ufficio, 
                    preda della quotidiana noia, 
                    provò a reindirizzare nell'interfaccia audio del suo sistema Linux un file di testo con il quale aveva giornalmente a che fare. Generò così un rumore sgradevole che ricordava quello di una vecchia stampante.<br/>

                    Passò qualche giorno e, preda della stessa quotidiana noia, volle provare ad invertire il processo e partendo dall'audio generato qualche giorno prima, tentò di ricostruire il suo odiato file. <br/>
                    Riuscendoci, decise di decodificare in quel modo i brani dei suoi artisti preferiti. <br/>

                    Portando avanti questa sperimentazione si rese conto che quasi ogni brano, una volta convertito in file di testo, aveva un senso compiuto. <br/>
                    Apparivano storie di cavalieri, documenti di anatomia umana, ricette, percorsi psicologici. <br/>
                    Alcune di queste conversioni invece, restituivano solo sequenze di caratteri e numeri senza alcun senso apparente. <br/>

                    Provò così a decodificarli, invece che in un file di testo, in immagini. <br/>
                    Vide fiori esotici mai documentati, specie sconosciute di animali, complicati sistemi di ingranaggi, entità mostruose e porzioni di universo con stelle e galassie. <br/>
                    Tutto era stato generato dall'alto contenuto creativo che aveva mosso quegli artisti nello scrivere. Come se queste densità fossero così forti da esondare, invadendo, all'insaputa dell'artista stesso, altri territori.
                </p>


            </div>

            {/* Colonna destra */}
            <div class="xl:w-1/2 w-full justify-center items-center px-4">

                <img class="hover:animate-none animate-pulse sm:mx-52"src={G}></img>
            </div>
        </div>
    );
}

export default Story;